import React from "react";
import bgWaitingRoom from "../../pictures/waiting_room.jpg";

const MeetingNotFoundScreen = () => {

    return (
        <div
            className="text-white dark:bg-meeting"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <div className="relative">
                <img src={bgWaitingRoom} alt="Fondo sala de espera" className="w-full h-full" />
                <div className="absolute top-0 left-0 w-full text-center text-white p-4 sm:text-2xl md:text-3xl lg:text-4xl">
                    <p>
                        Debe usar el enlace proporcionado.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MeetingNotFoundScreen;
