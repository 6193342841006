import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import animationData from "../../../src/static/animations/join_meeting4.json";
import Lottie from "lottie-react";
import logofna from "../../pictures/logofinal_gov.png"
import './WaitingRoom.css';
import moment from 'moment-timezone';
import { URL_API_FNA } from "../../utils/helper";

const WaitingRoomScreen = () => {
    const { uniqId } = useParams();
    const [dataPetition, setDataPetition] = useState({});
    const [dataSysVideo, setDataSysVideo] = useState([]);
    const [dataPetitionNotes, setDataPetitionNotes] = useState([]);
    const [message, setMessage] = useState("Cargando información...");
    const urlAPI = URL_API_FNA;
    const navigate = useNavigate();
    const animationDefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const isEmptyObj = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = {
                    "uniqId": uniqId
                };

                const response = await fetch(`${urlAPI}/api/v1/petition-checker/initNumber`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(data => {
                        if (data.status) {
                            return data.json()
                        }
                        throw new Error("Error al obtener información")
                    });

                if (response.data) {
                    setDataPetition(response.data.number.petition);
                    setDataSysVideo(response.data.sysVideo);
                }
                if (response.message === "Success") {
                    setMessage(`Hola ${response.data.number.petition.name} ya te encuentras en espera para ser atendido.`);
                }
                else {
                    setMessage(response.message);
                }

            } catch (error) {
                console.log({ error })
            }
        }

        fetchData();
    }, [])

    useEffect(() => {

        const fetchData = async () => {
            try {
                const data = {
                    "uniqId": uniqId
                };

                let response = await fetch(`${urlAPI}/api/v1/petition-checker/findRoom`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(data => {
                        if (data.status) {
                            return data.json()
                        }
                        throw new Error("Error al obtener información")
                    })

                if ('petitionRoom' in response.data) {
                    response = { ...response, dataSysVideo }
                    navigate(`/`, { replace: true, state: response });
                }
                else {
                    setDataPetitionNotes(response.data?.petitionNotes);
                }
            } catch (error) {
                console.error('Error al hacer la petición:', error);
            }

        };

        // Ejecutar la función después de x segundos (milisegundos)
        // setTimeout(fetchData, 10000);

        // Ejecutar la función cada x segundos (milisegundos)
        let intervalId;
        if (!isEmptyObj(dataPetition)) {
            intervalId = setInterval(fetchData, 10000);
        }
        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);
    }, [dataPetition, navigate, uniqId]
    );

    return (
        <div
            className="text-white dark:bg-meeting"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <div className="flex flex-col bg-[rgba(239,239,239,1)] rounded-3xl">
                <p className="text-center text-black p-10 m-2 sm:text-xl md:text-3xl lg:text-4xl"> {message}</p>
                <div className="flex xs:flex-row flex-col justify-center items-center">
                    <div>
                        <div className="relative bottom-4">
                            {
                                dataPetitionNotes.length > 0 && <div className="text-black bubble thought">
                                    <div className="flex flex-col">
                                        <p>{dataPetitionNotes[0].note}</p>
                                        <br />
                                        <p className="self-end">{moment(dataPetitionNotes[dataPetitionNotes.length - 1].date).format("hh:mm A")}</p>
                                    </div>
                                </div>
                            }
                            <img src={logofna} alt="logo fna" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div>
                        <Lottie
                            loop={animationDefaultOptions.loop}
                            autoplay={animationDefaultOptions.autoplay}
                            animationData={animationDefaultOptions.animationData}
                            rendererSettings={{
                                preserveAspectRatio:
                                    animationDefaultOptions.rendererSettings.preserveAspectRatio,
                            }}
                            style={{ width: "100%", height: '400px' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WaitingRoomScreen;
