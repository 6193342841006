import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMeetingAppContext } from "../MeetingAppContextDef";
import moment from "moment";
import ExpiredMeeting from "./screens/ExpiredMeeting";
import { useNavigate } from 'react-router-dom';

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  onClickStartMeeting,
  dataPetitionRoom
}) {
  const { setIsAssessorScreen } = useMeetingAppContext();

  const navigate = useNavigate();
  const dataVideoSDK = dataPetitionRoom !== null ? JSON.parse(dataPetitionRoom?.data?.petitionRoom?.rawJson) : '';
  const [meetingId, setMeetingId] = useState("");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);
  const isAssessor = dataPetitionRoom?.source === "asesorScreen";
  const assessorName = dataPetitionRoom?.assessorName;
  // const { darkMode, setDarkMode } = useMeetingAppContext();


  // const darkModeHandler = () => {
  //   setDarkMode(!darkMode);
  //   document.body.classList.toggle("dark");
  // }


  useEffect(() => {
    if (dataPetitionRoom === null) {
      navigate('/meetingNotFound', { replace: true })
    }
    const expirationDate = moment(dataPetitionRoom?.data?.petitionRoom?.petition?.datePetition, 'YYYY-MM-DD HH:mm');
    const currentDate = moment();

    if (currentDate.isAfter(expirationDate)) {
      console.log("LA CITA YA HA EXPIRADO");
    }

  }, [])

  useEffect(() => {
    if (isAssessor) {
      setParticipantName(assessorName);
      setIsAssessorScreen(true);
    } else {
      setParticipantName(dataPetitionRoom?.data?.petitionRoom?.petition?.name || "");
    }
  }, [assessorName, setParticipantName])

  return (
    <div
      className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
    >
      {iscreateMeetingClicked ? (
        <div className="border border-solid border-gray-400 rounded-xl px-4 py-3  flex items-center justify-center">
          <p className="dark:text-white text-base font-semibold">
            Código de Reunión:  <span>{meetingId}</span>
          </p>
          <button
            className="ml-2"
            onClick={() => {
              navigator.clipboard.writeText(meetingId);
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
          >
            {isCopied ? (
              <CheckIcon className="h-5 w-5 text-green-400" />
            ) : (
              <ClipboardIcon className="h-5 w-5 text-black dark:text-white" />
            )}
          </button>
        </div>
      ) : isJoinMeetingClicked ? (
        <>
          <input
            defaultValue={meetingId}
            onChange={(e) => {
              setMeetingId(e.target.value);
            }}
            placeholder={"Ingresar Código de Reunión"}
            className="px-4 py-3 dark:border dark:border-white bg-gray-200 dark:bg-gray-800 rounded-xl text-black dark:text-white w-full text-center placeholder-black dark:placeholder-white"
          />
          {meetingIdError && (
            <p className="text-xs text-red-600">{`Por favor ingresa un código válido`}</p>
          )}
        </>
      ) : null}

      {(iscreateMeetingClicked || isJoinMeetingClicked) && (
        <>
          <input
            value={participantName}
            onChange={(e) => setParticipantName(e.target.value)}
            placeholder="Ingresa tu nombre"
            className="px-4 py-3 mt-5 rounded-xl border bg-gray-200 dark:bg-gray-650 w-full text-center placeholder-black dark:placeholder-gray-500 dark:text-white"
          />

          <button
            disabled={participantName.length < 4}
            className={`w-full ${participantName.length < 4 ? "bg-gray-200 dark:bg-gray-650" : "bg-purple-350"
              } 
              ${participantName.length < 4 ? "text-black dark:text-white" : "text-white"} px-2 py-3 rounded-xl mt-5`}
            onClick={(e) => {
              if (iscreateMeetingClicked) {
                onClickStartMeeting();
              } else {
                if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                  onClickJoin(meetingId);
                } else setMeetingIdError(true);
              }
            }}
          >
            {iscreateMeetingClicked ? "Iniciar Reunión" : "Unirse a Reunión"}
          </button>
        </>
      )}

      {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 mt-4 flex flex-col">
          {/* <button onClick={() => darkModeHandler()} className="text-black bg-white dark:bg-gray-800 dark:text-white">
                  {

                    darkMode && <h1>Dark</h1> // render sunny when dark is true
                  }
                  {
                    !darkMode && <h1>White</h1> // render moon when dark is false
                  }
                </button> */}
          <div className="flex items-center justify-center flex-col w-full ">
            <input
              value={
                (isAssessor ? assessorName : dataPetitionRoom?.data?.petitionRoom?.petition?.name) || ""
              }
              placeholder="Ingresa tu nombre"
              disabled={true}
              className="px-4 py-3 mt-5 rounded-xl border bg-gray-200 dark:bg-gray-650 w-full text-center placeholder-black dark:placeholder-gray-500 dark:text-white"
            />
            <button
              className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl mt-5"
              onClick={(e) => {
                // setIsJoinMeetingClicked(true);
                // onClickJoin('m32a-6ai8-raaf');
                onClickJoin(dataVideoSDK.roomId);
              }}
            >
              Unirse a Reunión
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
