import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WaitingRoomScreen from "./components/screens/WaitingRoom";
import Error404Screen from "./components/screens/Error404Screen";
import ExpiredMeeting from "./components/screens/ExpiredMeeting";
import AssessorScreen from "./components/screens/AssessorScreen";
import MeetingNotFoundScreen from "./components/screens/MeetingNotFoundScreen";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import generateMuiTheme from "./mui/theme";
import { ThemeProvider } from "@mui/material/styles";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "waitingRoom/:uniqId",
    element: <WaitingRoomScreen />
  },
  {
    path: ":roomId/:uniqId/:na",
    element: <AssessorScreen />
  },
  {
    path: "meetingNotFound",
    element: <MeetingNotFoundScreen />
  },
  {
    path: "expiredMeeting",
    element: <ExpiredMeeting />
  },
  {
    path: "leave",
    element: <LeaveScreen />
  },
  {
    path: "*",
    element: <Error404Screen />
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));


window.addEventListener('error', (event) => {
  console.error('Uncaught error:', event.error);
});

window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
});

root.render(
  <>
    <ThemeProvider theme={generateMuiTheme()}>

      <ToastContainer
        toastClassName={() =>
          "relative flex py-4 px-3 rounded overflow-hidden cursor-pointer bg-white shadow-lg"
        }
        bodyClassName={() => "text-black text-base font-normal"}
        position="bottom-left"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={router} />
    </ThemeProvider>
  </>
);
