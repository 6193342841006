import { usePubSub, useParticipant } from '@videosdk.live/react-sdk';
import { useUploadFile } from '../hooks/useTemporaryFile';
import { useState } from 'react';

const CaptureImageListener = ({ localParticipantId }) => {

    const { captureImage } = useParticipant(localParticipantId);

    // subscribe to receive request
    usePubSub('IMAGE_CAPTURE', {
        onMessageReceived: (message) => {
            _handleOnImageCaptureMessageReceived(message);
        },
    });

    const _handleOnImageCaptureMessageReceived = (message) => {
        try {
            if (message.senderId !== localParticipantId) {
                // capture and store image when message received
                captureAndStoreImage({ senderId: message.senderId });
            }
        } catch (err) {
            window.alert("Error al tomar fotografía")
        }
    };

    async function captureAndStoreImage({ senderId }) {
        // capture image
        const base64Data = await captureImage(/* { height: 400, width: 400 } */);
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        const link = document.createElement('a');
        link.download = "Fotografía.png";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <>
    </>
};

export default CaptureImageListener;