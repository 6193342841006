import { useContext, createContext, useState, useEffect, useRef, useMemo } from "react";

export const MeetingAppContext = createContext();

export const useMeetingAppContext = () => useContext(MeetingAppContext);

export const meetingLayouts = {
  SPOTLIGHT: "SPOTLIGHT",
  SIDEBAR: "SIDEBAR",
  GRID: "GRID",
  UNPINNED_SIDEBAR: "UNPINNED_SIDEBAR",
  UNPINNED_SPOTLIGHT: "UNPINNED_SPOTLIGHT",
};

export const MeetingAppProvider = ({ children }) => {
  const [selectedMic, setSelectedMic] = useState({ id: null, label: null });
  const [selectedWebcam, setSelectedWebcam] = useState({
    id: null,
    label: null,
  });
  const [selectedSpeaker, setSelectedSpeaker] = useState({
    id: null,
    label: null,
  });
  const [isCameraPermissionAllowed, setIsCameraPermissionAllowed] =
    useState(null);
  const [isMicrophonePermissionAllowed, setIsMicrophonePermissionAllowed] =
    useState(null);
  const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([]);
  const [sideBarMode, setSideBarMode] = useState(null);
  const [pipMode, setPipMode] = useState(false);
  const [whiteBoard, setWhiteBoard] = useState(false);;
  const [startTimeMeeting, setStartTimeMeeting] = useState('');
  const [darkMode, setDarkMode] = useState(true);
  const [tokenVideoSDK, setTokenVideoSDK] = useState("");
  const [isAssessorScreen, setIsAssessorScreen] = useState(false);
  const [dataPetitionRoom, setDataPetitionRoom] = useState("");
  const [dataSessionRoom, setDataSessionRoom] = useState("");
  const [dataSysVideo, setDataSysVideo] = useState("");
  const [whiteboardState, setWhiteboardState] = useState({
    started: false,
    state: null,
  });

  const whiteboardStarted = useMemo(
    () => whiteboardState.started,
    [whiteboardState]
  );

  const useRaisedHandParticipants = () => {
    const raisedHandsParticipantsRef = useRef();

    const participantRaisedHand = (participantId) => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current];

      const newItem = { participantId, raisedHandOn: new Date().getTime() };

      const participantFound = raisedHandsParticipants.findIndex(
        ({ participantId: pID }) => pID === participantId
      );

      if (participantFound === -1) {
        raisedHandsParticipants.push(newItem);
      } else {
        raisedHandsParticipants[participantFound] = newItem;
      }

      setRaisedHandsParticipants(raisedHandsParticipants);
    };

    useEffect(() => {
      raisedHandsParticipantsRef.current = raisedHandsParticipants;
    }, [raisedHandsParticipants]);

    const _handleRemoveOld = () => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current];

      const now = new Date().getTime();

      const persisted = raisedHandsParticipants.filter(({ raisedHandOn }) => {
        return parseInt(raisedHandOn) + 15000 > parseInt(now);
      });

      if (raisedHandsParticipants.length !== persisted.length) {
        setRaisedHandsParticipants(persisted);
      }
    };

    useEffect(() => {
      const interval = setInterval(_handleRemoveOld, 1000);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return { participantRaisedHand };
  };

  return (
    <MeetingAppContext.Provider
      value={{
        // states

        raisedHandsParticipants,
        selectedMic,
        selectedWebcam,
        selectedSpeaker,
        sideBarMode,
        pipMode,
        isCameraPermissionAllowed,
        isMicrophonePermissionAllowed,
        whiteBoard,
        darkMode,
        startTimeMeeting,
        tokenVideoSDK,
        isAssessorScreen,
        dataPetitionRoom,
        dataSessionRoom,
        whiteboardStarted,
        whiteboardState,
        dataSysVideo,
        // setters

        setRaisedHandsParticipants,
        setSelectedMic,
        setSelectedWebcam,
        setSelectedSpeaker,
        setSideBarMode,
        setPipMode,
        useRaisedHandParticipants,
        setIsCameraPermissionAllowed,
        setIsMicrophonePermissionAllowed,
        setWhiteBoard,
        setDarkMode,
        setStartTimeMeeting,
        setTokenVideoSDK,
        setIsAssessorScreen,
        setDataPetitionRoom,
        setDataSessionRoom,
        setWhiteboardState,
        setDataSysVideo
      }}
    >
      {children}
    </MeetingAppContext.Provider>
  );
};
