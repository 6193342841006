import React, { useState, useEffect, useRef, createRef, memo } from "react";
import { Constants, useMeeting, useParticipant, usePubSub } from "@videosdk.live/react-sdk";
import { BottomBar } from "./components/BottomBar";
import { SidebarConatiner } from "../components/sidebar/SidebarContainer";
import MemorizedParticipantView from "./components/ParticipantView";
import { PresenterView } from "../components/PresenterView";
import { nameTructed, trimSnackBarText } from "../utils/helper";
import WaitingToJoinScreen from "../components/screens/WaitingToJoinScreen";
import ConfirmBox from "../components/ConfirmBox";
import useIsMobile from "../hooks/useIsMobile";
import useIsTab from "../hooks/useIsTab";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useMeetingAppContext } from "../MeetingAppContextDef";
import logoBlanco from "../pictures/logofinal_gov.png"
import useWhiteBoard from "../hooks/useWhiteBoard";
import PinnedLayoutViewContainer from "./PinnedLayoutViewContainer";

export function MeetingContainer({
  onMeetingLeave,
  setIsMeetingLeft,
}) {
  const {
    setSelectedMic,
    setSelectedWebcam,
    setSelectedSpeaker,
    darkMode,
    setDarkMode,
    setSideBarMode,
    whiteboardStarted
  } = useMeetingAppContext();
  useWhiteBoard();

  const [participantsData, setParticipantsData] = useState([]);

  const ParticipantMicStream = memo(({ participantId }) => {
    // Individual hook for each participant
    const { micStream } = useParticipant(participantId);

    useEffect(() => {

      if (micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        const audioElement = new Audio();
        audioElement.srcObject = mediaStream;
        audioElement.play();

      }
    }, [micStream, participantId]);

    return null;
  }, [participantsData]);


  const darkModeHandler = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("dark");
  }
  const { useRaisedHandParticipants, startTimeMeeting, setStartTimeMeeting, tokenVideoSDK, setDataSessionRoom } = useMeetingAppContext();
  const bottomBarHeight = 60;

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [localParticipantAllowedJoin, setLocalParticipantAllowedJoin] = useState(null);
  const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
  const [meetingError, setMeetingError] = useState(false);

  const mMeetingRef = useRef();
  const containerRef = createRef();
  const containerHeightRef = useRef();
  const containerWidthRef = useRef();

  useEffect(() => {
    containerHeightRef.current = containerHeight;
    containerWidthRef.current = containerWidth;
  }, [containerHeight, containerWidth]);

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isLGDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  const isXLDesktop = useMediaQuery({ minWidth: 1440 });

  const sideBarContainerWidth = isXLDesktop
    ? 400
    : isLGDesktop
      ? 360
      : isTab
        ? 320
        : isMobile
          ? 280
          : 240;

  useEffect(() => {
    containerRef.current?.offsetHeight &&
      setContainerHeight(containerRef.current.offsetHeight);
    containerRef.current?.offsetWidth &&
      setContainerWidth(containerRef.current.offsetWidth);

    window.addEventListener("resize", ({ target }) => {
      containerRef.current?.offsetHeight &&
        setContainerHeight(containerRef.current.offsetHeight);
      containerRef.current?.offsetWidth &&
        setContainerWidth(containerRef.current.offsetWidth);
    });
  }, [containerRef]);

  const { participantRaisedHand } = useRaisedHandParticipants();

  const _handleMeetingLeft = () => {
    setIsMeetingLeft(true);
  };

  const _handleOnRecordingStateChanged = ({ status }) => {
    if (
      status === Constants.recordingEvents.RECORDING_STARTED ||
      status === Constants.recordingEvents.RECORDING_STOPPED
    ) {
      toast(
        `${status === Constants.recordingEvents.RECORDING_STARTED
          ? "Se ha iniciado la grabación"
          : "Se ha detenido la grabación"
        }`,
        {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeButton: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  function onParticipantJoined(participant) {
    // Change quality to low, med or high based on resolution
    participant && participant.setQuality("high");
  }


  function onEntryResponded(participantId, name) {
    if (mMeetingRef.current?.localParticipant?.id === participantId) {
      if (name === "allowed") {
        setLocalParticipantAllowedJoin(true);
      } else {
        setLocalParticipantAllowedJoin(false);
        setTimeout(() => {
          _handleMeetingLeft();
        }, 3000);
      }
    }
  }

  function onMeetingJoined() {
    console.log("onMeetingJoined");
  }

  function onMeetingLeft() {
    setSelectedMic({ id: null, label: null })
    setSelectedWebcam({ id: null, label: null })
    setSelectedSpeaker({ id: null, label: null })
    onMeetingLeave();
  }

  const _handleOnError = (data) => {
    const { code, message } = data;
    console.log("meetingErr", code, message)

    const joiningErrCodes = [
      4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010,
    ];

    const isJoiningError = joiningErrCodes.findIndex((c) => c === code) !== -1;
    const isCriticalError = `${code}`.startsWith("500");

    new Audio(
      isCriticalError
        ? `https://static.videosdk.live/prebuilt/notification_critical_err.mp3`
        : `https://static.videosdk.live/prebuilt/notification_err.mp3`
    ).play();

    setMeetingErrorVisible(true);
    setMeetingError({
      code,
      message: isJoiningError ? "Unable to join meeting!" : message,
    });
  };

  const mMeeting = useMeeting({
    onParticipantJoined,
    onEntryResponded,
    onMeetingJoined,
    onMeetingLeft,
    onError: _handleOnError,
    onRecordingStateChanged: _handleOnRecordingStateChanged,
  });



  const isPresenting = mMeeting.presenterId ? true : false;

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const participantIds = Array.from(mMeeting.participants.keys());

      setParticipantsData(participantIds);
    }, 500);


    return () => clearTimeout(debounceTimeout);
  }, [mMeeting.participants]);



  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  useEffect(() => {
    if (mMeeting.meetingId) {

      setTimeout(() => {
        fetchData();
      }, "2000");

      const fetchData = async () => {
        const options = {
          method: "GET",
          headers: {
            "Authorization": tokenVideoSDK,
            "Content-Type": "application/json",
          },
        };
        const url = `https://api.videosdk.live/v2/sessions/?roomId=${mMeetingRef.current.meetingId}&page=1&perPage=20`;
        const response = await fetch(url, options);
        const data = await response.json();

        const currentSession = data.data.filter(session => session.status === "ongoing")[0];

        const urlSessions = `https://api.videosdk.live/v2/sessions/${currentSession.id}`;
        const responseSessions = await fetch(urlSessions, options);
        const dataSessions = await responseSessions.json();
        setStartTimeMeeting(dataSessions.start);
        setDataSessionRoom(dataSessions);
      }
    }
  }, [mMeeting.meetingId]);

  usePubSub("RAISE_HAND", {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName } = data;

      const isLocal = senderId === localParticipantId;

      new Audio(
        `https://static.videosdk.live/prebuilt/notification.mp3`
      ).play();

      toast(`${isLocal ? "" : nameTructed(senderName, 15)} ha leventado la mano 🖐🏼`, {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      participantRaisedHand(senderId);
    },
  });


  usePubSub("CHAT", {
    onMessageReceived: (data) => {
      setSideBarMode("CHAT");
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName, message } = data;

      const isLocal = senderId === localParticipantId;

      if (!isLocal) {
        new Audio(
          `https://static.videosdk.live/prebuilt/notification.mp3`
        ).play();

        toast(
          `${trimSnackBarText(
            `${nameTructed(senderName, 15)} ha enviado: ${message.startsWith('inputFile:') ? "un archivo" : message}`
          )}`,
          {
            position: "bottom-left",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    },
  });

  const handleErrorText = (message) => {
    let text = "";
    if (message === "Screen sharing permission denied.") {
      text = "Permiso para compartir pantalla denegado."
    }
    return text;
  }

  return (
    <div className={`fixed inset-0 bg-meeting`}>
      {/* <button onClick={() => darkModeHandler()} className="text-black bg-white dark:bg-gray-800 dark:text-white">
        {

          darkMode && <h1>Dark</h1> // render sunny when dark is true
        }
        {
          !darkMode && <h1>White</h1> // render moon when dark is false
        }
      </button> */}
      <div ref={containerRef} className="h-full flex flex-col">
        <div>
          <img src={logoBlanco} alt="logo fna" className="object-scale-down w-24 p-2" />
        </div>
        {typeof localParticipantAllowedJoin === "boolean" ? (
          localParticipantAllowedJoin ? (
            <>
              <div className={` flex flex-1 flex-row  `}>
                <div className={`flex flex-1 `}>
                  {isPresenting || whiteboardStarted ?
                    (
                      <PresenterView height={containerHeight - bottomBarHeight} whiteBoardStarted={whiteboardStarted} containerWidth={containerWidth} isMobile={isMobile} />
                    )
                    : null}
                  {(isPresenting || whiteboardStarted) && isMobile ? (participantsData.map((participantId) => (
                    <ParticipantMicStream key={participantId} participantId={participantId} />
                  ))
                  ) : (
                    <MemorizedParticipantView isPresenting={isPresenting || whiteboardStarted} />
                  )}

                </div>

                <SidebarConatiner
                  height={containerHeight - bottomBarHeight}
                  sideBarContainerWidth={sideBarContainerWidth}
                />
              </div>

              <BottomBar
                bottomBarHeight={bottomBarHeight}
                setIsMeetingLeft={setIsMeetingLeft}
                dateSession={startTimeMeeting}
              />
            </>
          ) : (
            <></>
          )
        ) : (
          !mMeeting.isMeetingJoined && <WaitingToJoinScreen />
        )}
        <ConfirmBox
          open={meetingErrorVisible}
          successText="Aceptar"
          onSuccess={() => {
            setMeetingErrorVisible(false);
          }}
          title={`Código de error: ${meetingError.code}`}
          subTitle={handleErrorText(meetingError.message)}
        />
      </div>
    </div>
  );
}
