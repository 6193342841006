import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ConfirmBox from "../ConfirmBox";

export function LeaveScreen({ setIsMeetingLeft }) {
  const location = useLocation();
  const [showMessage, setShowMessage] = useState(false);
  const { tokenVideoSDK, isAssessorScreen, dataSessionRoom } = location.state || {};

  // useEffect(() => {
  //   const timer = setTimeout(async () => {
  //     try {
  //       if (!tokenVideoSDK) {
  //         return;
  //       }
  //       if (isAssessorScreen) {
  //         setShowMessage(true);
  //         const options = {
  //           method: "GET",
  //           headers: {
  //             "Authorization": tokenVideoSDK,
  //             "Content-Type": "application/json",
  //           },
  //         };
  //         const url = `https://api.videosdk.live/v2/sessions/${dataSessionRoom.id}`;
  //         const response = await fetch(url, options);
  //         const dataSession = await response.json();
  //         if (dataSession.chatLink) {
  //           window.open(dataSession.chatLink, '_blank');
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setShowMessage(false);
  //     }
  //   }, 1000);


  //   return () => clearTimeout(timer);
  // }, []);


  return (
    <div className={`h-screen flex flex-col flex-1 items-center justify-center bg-meeting`}>
      <h1 className="text-black dark:text-white text-4xl">Haz salido de la reunión</h1>
      <div className="mt-12">
      </div>
      {/* <ConfirmBox
        open={showMessage}
        successText=""
        onSuccess={() => {
          // setDlgDevices(false);
        }}
        title="Descargando chat de la reunión"
        subTitle="Espere por favor"
        subTitleColor={"#fff"}
      /> */}
    </div>
  );
}
