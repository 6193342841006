import { PaperAirplaneIcon, PaperClipIcon } from "@heroicons/react/solid";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import React, { useEffect, useRef, useState } from "react";
import { formatAMPM, json_verify, nameTructed, URL_API_FNA } from "../../utils/helper";
import ConfirmBox from "../ConfirmBox";
import { useMeetingAppContext } from "../../MeetingAppContextDef";
import { sideBarModes } from "../../utils/common";

const ChatMessage = ({ senderId, senderName, text, timestamp }) => {
  const mMeeting = useMeeting();

  const localParticipantId = mMeeting?.localParticipant?.id;
  const localSender = localParticipantId === senderId;
  return (
    <div
      className={`flex ${localSender ? "justify-end" : "justify-start"} mt-4`}
      style={{
        maxWidth: "100%",
      }}
    >
      <div
        className={`flex ${localSender ? "items-end" : "items-start"
          } flex-col py-1 px-2 rounded-md bg-gray-250 dark:bg-gray-700`}
      >
        <p style={{ color: "#ffffff80" }}>
          {localSender ? "Has enviado" : `${nameTructed(senderName, 15)} ha enviado`}
        </p>
        <div>
          {
            !text.startsWith("inputFile:") ?
              <p className="inline-block whitespace-pre-wrap break-words text-right text-white">
                {text}
              </p>
              :
              <a href={`https://apiturnos.customersolutions.click/main/getPetitionFile?uniqId=${text.split("inputFile:")[1]}`} target="_blank" rel="noreferrer" className="inline-block whitespace-pre-wrap text-right text-white cursor-pointer underline" download={text.split("inputFile:")[2]} style={{ overflowWrap: 'anywhere' }}>
                {text.split("inputFile:")[2]}
              </a>
          }
        </div>
        <div className="mt-1">
          <p className="text-xs italic" style={{ color: "#ffffff80" }}>
            {formatAMPM(new Date(timestamp))}
          </p>
        </div>
      </div>
    </div>
  );
};

const fetchCreateFile = async ({ uniqId, source = "Asesor", base64, name }) => {
  const urlAPI = URL_API_FNA;

  const optionRequest = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "uniqId": uniqId,
      "source": source,
      "base64": base64,
      "name": name
    })
  }

  const response = await fetch(`${urlAPI}/api/v1/petition-checker/createFile`, optionRequest)
    .then((data => {
      if (data.ok) {
        return data.json()
      }
      throw new Error("Error al obtener información")
    })).catch(error =>
      console.log("Error al subir archivo:", error)
    )

  return response;
}

const fetchSendMessage = async ({ uniqId, source = "Asesor", message }) => {
  const urlAPI = URL_API_FNA;

  const optionRequest = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "uniqId": uniqId,
      "source": source,
      "message": message,
    })
  }

  const response = await fetch(`${urlAPI}/api/v1/petition-checker/createChatMessage`, optionRequest)
    .then((data => {
      if (data.ok) {
        return data.json()
      }
      throw new Error("Error al enviar mensaje")
    })).catch(error =>
      console.log(error)
    )

  return response;
}

const ChatInput = ({ inputHeight }) => {
  const [disabledSend, setDisabledSend] = useState(false);
  const { dataPetitionRoom, isAssessorScreen } = useMeetingAppContext();
  const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const { publish } = usePubSub("CHAT");
  const input = useRef();
  const fileInputRef = useRef();
  const allowedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/jpeg',
    'image/png'
  ];
  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];



    let base64String;
    if (file) {
      const fileSizeInMB = file.size / 1024 / 1024;
      if (!allowedTypes.includes(file.type)) {
        setMeetingErrorVisible(true);
        setMessageError("Tipo de archivo no permitido.");
        fileInputRef.current.value = '';
        return;
      }
      if (fileSizeInMB > 5) {
        setMeetingErrorVisible(true);
        setMessageError("El tamaño máximo permitido son 5 MB");
        fileInputRef.current.value = '';
        return;
      }
      base64String = await convertToBase64(file);
      let fileURL = await fetchCreateFile({
        base64: base64String,
        name: file.name,
        uniqId: dataPetitionRoom.data.petitionRoom.petition.uniqId,
        source: isAssessorScreen ? "Asesor" : "Cliente"
      })

      if (fileURL.success) {
        publish(`inputFile:${fileURL.data.petitionFile.uniqId}inputFile:${file.name}`, { persist: true });
      } else {
        setMeetingErrorVisible(true)
      }
    }

  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div
      className="w-full flex items-center px-2"
      style={{ height: inputHeight }}
    >
      <div className="relative  w-full">
        <span className="absolute inset-y-0 right-0 flex flex-col mr-2 rotate-90">
          <button
            disabled={disabledSend || message.length < 2}
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
            onClick={async () => {
              const messageText = message.trim();
              if (messageText.length > 0) {
                setDisabledSend(true);
                await fetchSendMessage({
                  message: messageText,
                  uniqId: dataPetitionRoom.data.petitionRoom.petition.uniqId,
                  source: isAssessorScreen ? "Asesor" : "Cliente"
                })
                publish(messageText, { persist: true });
                setTimeout(() => {
                  setMessage("");
                }, 100);
                input.current?.focus();
                setDisabledSend(false);
              }
            }}
          >
            <PaperAirplaneIcon
              className={`w-6 h-6 ${disabledSend || message.length < 2 ? "text-gray-500 " : "dark:text-white"
                }`}
            />
          </button>
          <input type="file" id="archivoInput" accept=".pdf, .doc, .docx, .xls, .xlsx, image/jpeg, image/png" className="hidden p-1 focus:outline-none focus:shadow-outline" onChange={handleFileInputChange} ref={fileInputRef} />
          <label htmlFor="archivoInput" className="p-1 focus:outline-none focus:shadow-outline cursor-pointer">
            <PaperClipIcon /* htmlFor="archivoInput" */
              className={`w-6 h-6 dark:text-white
                }`}
            />
          </label>
        </span>
        <input
          type="text"
          className="py-4 text-base text-black dark:text-white border-gray-400 border bg-white dark:bg-gray-750 rounded pr-10 pl-2 focus:outline-none w-full"
          placeholder="Escribir Mensaje"
          autoComplete="off"
          ref={input}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onKeyPress={async (e) => {
            if (disabledSend) {
              return;
            }
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              setDisabledSend(true);
              const messageText = message.trim();

              if (messageText.length > 0) {
                await fetchSendMessage({
                  message: messageText,
                  uniqId: dataPetitionRoom.data.petitionRoom.petition.uniqId,
                  source: isAssessorScreen ? "Asesor" : "Cliente"
                })
                publish(messageText, { persist: true });
                setTimeout(() => {
                  setMessage("");
                }, 100);
                input.current?.focus();
                setDisabledSend(false);
              }
            }
          }}
        />

      </div>
      <ConfirmBox
        open={meetingErrorVisible}
        successText="Aceptar"
        onSuccess={() => {
          setMeetingErrorVisible(false);
        }}
        title={`Error`}
        subTitle={messageError}
      />
    </div>
  );
};

const ChatMessages = ({ listHeight }) => {
  const listRef = useRef();
  const { messages } = usePubSub("CHAT");

  const scrollToBottom = (data) => {
    if (!data) {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    } else {
      const { text } = data;

      if (json_verify(text)) {
        const { type } = JSON.parse(text);
        if (type === "CHAT") {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
          }
        }
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return messages ? (
    <div ref={listRef} style={{ overflowY: "scroll", height: listHeight }}>
      <div className="p-4">
        {messages.map((msg, i) => {
          const { senderId, senderName, message, timestamp } = msg;
          return (
            <ChatMessage
              key={`chat_item_${i}`}
              {...{ senderId, senderName, text: message, timestamp }}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <p>No messages</p>
  );
};

export function ChatPanel({ panelHeight }) {
  const inputHeight = 72;
  const listHeight = panelHeight - inputHeight;

  return (
    <div>
      <ChatMessages listHeight={listHeight} />
      <ChatInput inputHeight={inputHeight} />
    </div>
  );
}
