import { useFile } from '@videosdk.live/react-sdk';

export function useUploadFile() {
  const { uploadBase64File } = useFile();

  const uploadFile = async (base64Data, token, fileName) => {
      const url = await uploadBase64File({ base64Data, token, fileName })
      return url;
  };

  return { uploadFile };
}


export function useFetchFile() {
  const { fetchBase64File } = useFile();

  const fetchFile = async (url, token) => {
    try {
      const base64 = await fetchBase64File({ url, token });
      return base64;
    } catch (error) {
      throw new Error(error)
    }
  };

  return { fetchFile };
}